import React from "react"
import { H1, H2 } from "../../components/elements/Headings"
import {
  PageTitleContainer,
  UtilityBoxContainer,
} from "../../components/layout/Containers"
import Layout from "../../components/layout/Layout"
import SEO from "../../components/seo"
import UtilityBox from "../../components/UtilityBox"

const DatePage = () => {
  return (
    <>
      <SEO title="Date utilities" />
      <Layout>
        <PageTitleContainer>
          <H1>Date Utilities</H1>
          <H2>All the utilities available</H2>
        </PageTitleContainer>
        <UtilityBoxContainer>
          <UtilityBox
            key={"timestampToDate"}
            path="/date/timestampToDate"
            name="Timestamp to Date"
            description="Convert a unix timestamp to regular Date"
          />{" "}
          <UtilityBox
            key={"dateToTimestamp"}
            path="/date/dateToTimestamp"
            name="Date to Timestamp"
            description="Convert a regular date to unix timestamp"
          />
          <UtilityBox
            key={"differenceInDays"}
            path="/date/differenceInDays"
            name="Difference In Days"
            description="Calculate the difference in days"
          />
        </UtilityBoxContainer>
      </Layout>
    </>
  )
}

export default DatePage
