import { Transition } from "@headlessui/react"
import { Link } from "gatsby"
import React from "react"

type UtilityBoxProps = {
  path: string
  name: string
  description: string
}

const UtilityBox = ({ path, name, description }: UtilityBoxProps) => {
  return (
    <Link to={path}>
      <Transition
        show={true}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <div className="bg-white rounded-lg border border-secondary shadow-lg transition-shadow duration-150 hover:shadow-xl p-4 w-full">
          <div className="text-3xl md:text-2xl text-primary font-semibold">
            {name}
          </div>
          <div className="font-light md:text-sm">{description}</div>
        </div>
      </Transition>
    </Link>
  )
}

export default UtilityBox
